import { CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react'
import { stopLoading } from '../redux/slices/dashboard';
import { dispatch, useSelector } from '../redux/store';


const Loader = styled(CircularProgress)({
    position: "fixed",
    left: "50%",
    top: "50%",
    width: "100%",
    height: "100%",
    zIndex: 9999
})
const CircularLoader = () => {
    const { isLoading } = useSelector((state) => state.dashboard);

    useEffect(() => {
        dispatch(stopLoading())
    }, [])

    return (
        <div>
            {isLoading && <Loader />}
        </div>
    )
}

export default CircularLoader