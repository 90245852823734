import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, styled } from '@mui/material';
import footerlogo from '../assets/logo3.png';

const LogoBox = styled(Box)(({ theme }) => ({
  width: 175,
  height: 40,
  [theme.breakpoints.down('md')]: {
    width: 85,
  },
}));

// ----------------------------------------------------------------------

LogoWhite.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LogoWhite({ disabledLink = false, sx }) {
  const logo = (
    <div>
      <LogoBox sx={{ ...sx }}>
        <img src={footerlogo} alt="logo" />
      </LogoBox>
    </div>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
