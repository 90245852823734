import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPasswordPage /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'app',
          element: <GeneralApp />,
        },
        {
          path: 'profile',
          element: <DashboardProfile />,
        },
        { path: 'campaign', element: <CampaignList /> },
        // { path: 'donation', element: <DonationList /> },
        { path: 'withdrawal', element: <WithdrawalList /> },
        {
          path: 'donation',
          children: [
            { element: <Navigate to="/dashboard/donation/list" replace />, index: true },
            { path: 'list', element: <DonationList /> },
            // { path: ':id', element: <DonationDetails /> },
          ],
        },
        {
          path: 'withdrawal',
          children: [
            { element: <Navigate to="/dashboard/withdrawal/list" replace />, index: true },
            // { path: ':id', element: <WithdrawalCreate /> },
            { path: 'list', element: <WithdrawalList /> },
            { path: 'create/:id', element: <WithdrawalCreate /> },
          ],
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'organisation',
          children: [
            { element: <Navigate to="/dashboard/organisation/profile" replace />, index: true },
            { path: 'profile', element: <OrganisationProfiler /> },
            { path: 'account', element: <OrganisationAccount /> },
          ],
        },
      ],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about', element: <About /> },
        { path: 'medical', element: <Medical /> },
        { path: 'charityplatform', element: <WeAreCharityPlatform /> },
        { path: 'volunteer', element: <Volunteer /> },
        { path: 'relief', element: <Relief /> },
        { path: 'how-it-works', element: <Howitworks /> },
        { path: 'campaign', element: <CampaignFilter /> },
        { path: '/fundraisers/new', element: <CampaignCreate /> },
        { path: '/fundraisers/:title/:id/edit', element: <CampaignEdit /> },
        { path: '/fundraisers/:title/:id', element: <CampaignView /> },
        { path: '/fundraisers/:id', element: <CampaignView /> },
        { path: '/donation-successful/:id', element: <PaymentSuccessful /> },
        { path: '/donation-claim/:id', element: <Payment80Gclaim /> },
        { path: 'contact', element: <Contact /> },
        { path: 'ngo', element: <DiscoverNgo /> },
        { path: 'ngo/:id', element: <DisplayProfile /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'linktree', element: <Linktree /> },
        { path: 'privacy-policy', element: <PolicyPage /> },
        { path: 'terms-conditions', element: <Terms /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <ShortUrl /> },
        { path: 'post/:id', element: <PostModal /> },

        { path: 'catalogue', element: <Catalogue /> },
        { path: 'csr', element: <CSR /> },
        { path: 'ngo', element: <Ngo /> },
        { path: 'charity', element: <Charity /> },
        { path: 'donation', element: <Donation /> },
        { path: 'taxbenefits', element: <TaxBenefits /> },
        { path: 'animal', element: <Animal /> },
        { path: 'spiritual', element: <Spiritual /> },
        { path: 'personalissue', element: <PersonalIssue /> },
        { path: 'education', element: <Education /> },
        { path: 'socialwelfare', element: <SocialWelfare /> },
        { path: 'medical', element: <Medical /> },
        { path: 'women', element: <Women /> },
        { path: 'environment', element: <Env /> },
        { path: 'socialimpact', element: <SocialImpact /> },
        { path: 'child', element: <Child /> },
        { path: 'poverty', element: <Poverty /> },
        { path: 'relief', element: <Relief /> },
        { path: 'volunteer', element: <Volunteer /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));

// DASHBOARD

// GENERAL
const NgoProfile = Loadable(lazy(() => import('../pages/dashboard/ngo/NgoProfilePage')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const DashboardProfile = Loadable(lazy(() => import('../pages/dashboard/DashboardProfile')));
const CampaignList = Loadable(lazy(() => import('../pages/dashboard/CampaignList')));
const DonationList = Loadable(lazy(() => import('../pages/dashboard/DonationList')));
const WithdrawalList = Loadable(lazy(() => import('../pages/dashboard/WithdrawalList')));

// Donantion
// const DonationDetails = Loadable(lazy(() => import('../pages/dashboard/DonationDetails')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

// Organisation
const OrganisationProfiler = Loadable(lazy(() => import('../pages/dashboard/ngo/NgoProfilePage')));
const OrganisationAccount = Loadable(lazy(() => import('../pages/dashboard/ngo/NgoAccount')));

// withdrawal
const WithdrawalCreate = Loadable(lazy(() => import('../pages/dashboard/WithdrawalCreate')));

// TEST RENDER PAGE BY ROLE

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const PolicyPage = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const Terms = Loadable(lazy(() => import('../pages/Terms')));
const Howitworks = Loadable(lazy(() => import('../pages/howitworks')));

const CampaignCreate = Loadable(lazy(() => import('../pages/CampaignCreate')));
const CampaignEdit = Loadable(lazy(() => import('../pages/CampaignEdit')));
const CampaignView = Loadable(lazy(() => import('../pages/CampiagnView')));
const CampaignFilter = Loadable(lazy(() => import('../pages/CampiagnFilter')));

const PaymentSuccessful = Loadable(lazy(() => import('../pages/PaymentSuccessful')));
const Payment80Gclaim = Loadable(lazy(() => import('../pages/Payment80Gclaim')));

// const Medical = Loadable(lazy(() => import('../pages/Medical')));
// const Volunteer = Loadable(lazy(() => import('../pages/Volunteer')));
// const Relief = Loadable(lazy(() => import('../pages/Relief')));
const PostModal = Loadable(lazy(() => import('../pages/PostModal')));

// catelogue
const Catalogue = Loadable(lazy(() => import('../pages/catalog/oldformat')));
const CSR = Loadable(lazy(() => import('../pages/catalog/CSR')));
const Ngo = Loadable(lazy(() => import('../pages/catalog/Ngo')));
const Charity = Loadable(lazy(() => import('../pages/catalog/Charity')));
const TaxBenefits = Loadable(lazy(() => import('../pages/catalog/TaxBenefits')));
const WeAreCharityPlatform = Loadable(lazy(() => import('../pages/catalog/WeAreCharityPlatform')));
const Donation = Loadable(lazy(() => import('../pages/catalog/Donation')));
const Animal = Loadable(lazy(() => import('../pages/catalog/Animal')));
const Spiritual = Loadable(lazy(() => import('../pages/catalog/Spiritual')));
const Medical = Loadable(lazy(() => import('../pages/catalog/Medical')));
const PersonalIssue = Loadable(lazy(() => import('../pages/catalog/PersonalIssue')));
const SocialWelfare = Loadable(lazy(() => import('../pages/catalog/SocialWelfare')));
const Education = Loadable(lazy(() => import('../pages/catalog/Education')));
const Women = Loadable(lazy(() => import('../pages/catalog/Women')));
const Env = Loadable(lazy(() => import('../pages/catalog/Env')));
const Child = Loadable(lazy(() => import('../pages/catalog/Child')));
const SocialImpact = Loadable(lazy(() => import('../pages/catalog/SocialImpact')));
const Poverty = Loadable(lazy(() => import('../pages/catalog/Poverty')));
const Relief = Loadable(lazy(() => import('../pages/catalog/Relief')));
const Volunteer = Loadable(lazy(() => import('../pages/catalog/Volunteer')));

const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const DiscoverNgo = Loadable(lazy(() => import('../pages/ngo/DiscoverNgo')));
const DisplayProfile = Loadable(lazy(() => import('../pages/ngo/NgoPublicProfile')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Linktree = Loadable(lazy(() => import('../pages/Linktree')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const ShortUrl = Loadable(lazy(() => import('../pages/ShortUrl')));
const Strays = Loadable(lazy(() => import('../pages/causes/Strays')));
const Environment = Loadable(lazy(() => import('../pages/causes/Environment')));
